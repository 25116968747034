@import "./assets/font/iconfont.css";
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6em;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  /* background: repeating-linear-gradient(
    30deg,
    #79b,
    #79b 15%,
    #58a 0,
    #58a 30%
  ); */

  --hover: #4786d6;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

h2,
h3,
h4 {
  color: rgba(0, 0, 0, 0.8);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

p,
ul,
ol,
dl {
  margin: 1em 0;
}

p {
  line-height: 1.9em;
  font-weight: 400;
  font-size: 14px;
}

a {
  text-decoration: none;
}

a:link,
a:visited {
  opacity: 1;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  transition: all 0.15s linear;
  color: #424242;
}

a:hover,
a:active {
  color: #4786d6;
}

li {
  list-style: none;
}

/* pre {
  background-color: #f8f8f8 !important;
}*/

code {
  padding: 1px 3px;
  font-family: Inconsolata, monospace, sans-serif;
  font-size: 0.85em;
  white-space: pre-wrap;
  border: 1px solid #e3edf3;
  background: #f7f7f9;
  color: #d14;
  border-radius: 2px;
}

img {
  width: 100%;
  cursor: pointer;
}

.bb {
  border-bottom: 1px solid #eee;
}

.pr {
  position: relative;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}
