.previewImg {
  width: 100%;
  height: 100%;
  background: rgba(2, 2, 2, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  .close {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    color: #222;
    font-size: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(163, 162, 162);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close:hover {
    color: var(--hover);
  }

  .icon-arrow-right-filling,
  .icon-arrow-left-filling {
    position: fixed;
    top: 50%;
    font-size: 35px;
    color: white;
    cursor: pointer;
  }

  .icon-arrow-right-filling:hover,
  .icon-arrow-left-filling:hover {
    transform: scale(1.5);
    transition: all 0.2s;
  }

  .icon-arrow-left-filling {
    left: 5%;
  }

  .icon-arrow-right-filling {
    right: 5%;
  }
}
